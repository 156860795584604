/* eslint-disable */
export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}



export const ReportParameterMappings: any = {

  dailyReportParameterMapping: {
    'EVP': 'UserPrivilegeEVP',
    'SVP': 'UserPrivilegeSVP',
    'REGION': 'UserPrivilegeREGION',
    'MARKET': 'UserPrivilegeMARKET',
    'STORE_NUMBER': 'StoreQuerySTOREID',
    'TodayDefault': 'CalendarDate'
  },
  dapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'Date1': 'StartDate'
  },
  inventoryRecapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  itemBeingServiceReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  salesPersonReportParameterMapping: {
    'EVP': 'UserIDEVP',
    'SVP': 'UserIDSVP',
    'REGION': 'UserIDREGION',
    'MARKET': 'UserIDMARKET',
    'STORE_NUMBER': 'SalesPersonRecapSTORENUMBER',
    'Date': 'EndDate'
  },
  skipStolenReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate'
  },

  exceptionAuditTrailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate',
    'MODULE': 'ModuleType',
    'EMPLOYEE': 'Employee'
  },
  pettyCashAnalysisDetailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'REGION',
    'MARKET': 'MARKET',
    'STORE_NUMBER': 'STORENUMBER',
    'DAY_DATE': 'CalendarDate',
    'EMPLOYEE': 'PettyCashAnalysisdetailEMPLOYEE'
  },
  pettyCashAnalysisSummaryReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'REGION',
    'MARKET': 'MARKET',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'StartDate'
  },
  receiptAuditTrailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'DAY_DATE': 'EndDate',
    "EMPLOYEE": 'employee',
    "FTE_Label": 'FreeTimeExtension',
    "Receipt_Label": "Receipt",
    "Pay_origin_label": "payment_origin",

  },
  inventoryOutAuditTrialReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate'
  },
  inventoryInAuditTrialReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate'
  },
  idleInventoryReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'DaysIdleFilter': 'IdleDaysFilter'
  },
  onRentReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'Customers_Label': 'Customers',
    'Routes_Label': 'Routes',
    'ROUTE': 'Route'
  },
  tatReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'EMPLOYEENAME': 'Employee',
    'DAY_DATE': 'EndDate'
  }
}

export const DUAL_WORKSPACE_REPORTS = ['DailyActivityPlanner', 'SalesPersonRecapReport', 'SkipStolenReport', 'ExceptionAuditTrial', 'ReceiptAuditTrailReport', 'PettyCashAnalysisDetailReport', 'PettyCashAnalysisSummaryReport', 'InventoryInAuditTrialReport', 'InventoryOutAuditTrialReport', 'ItemBeingServicedReport', 'IdleInventoryReport', 'OnRentInventoryReport', 'InventoryRecapReport', 'TransactionAuditTrialReport', 'DailyReport']


export const TATRDLSubReportName = [
  'ChargeOffAgreement',
  'TransferInAgreement',
  'TransferOutAgreement',
  'ChargeOffCashSale',
  'ChargeOffInventory',
  'Deliveries',
  'TransferInInventory',
  'TransferOutInventory',
  'ManualPO',
  'Returns',
  'PrelimCancel',
  'PrelimInventory',
  'PrelimPaid',
  'PromotionalLoaner',
  'Receivers',
  'ServiceLoaner',
  'Switchouts',
  'ChargeOffEPOPIF'
];

export const TATRDLSubReportMapping = {
  'TransactionAuditTrialReportRDLId1': 'ChargeOffAgreement',
  'TransactionAuditTrialReportRDLId2': 'TransferInAgreement',
  'TransactionAuditTrialReportRDLId3': 'TransferOutAgreement',
  'TransactionAuditTrialReportRDLId4': 'ChargeOffCashSale',
  'TransactionAuditTrialReportRDLId5': 'ChargeOffInventory',
  'TransactionAuditTrialReportRDLId6': 'Deliveries',
  'TransactionAuditTrialReportRDLId7': 'TransferInInventory',
  'TransactionAuditTrialReportRDLId8': 'TransferOutInventory',
  'TransactionAuditTrialReportRDLId9': 'ManualPO',
  'TransactionAuditTrialReportRDLId10': 'Returns',
  'TransactionAuditTrialReportRDLId11': 'PrelimCancel',
  'TransactionAuditTrialReportRDLId12': 'PrelimInventory',
  'TransactionAuditTrialReportRDLId13': 'PrelimPaid',
  'TransactionAuditTrialReportRDLId14': 'PromotionalLoaner',
  'TransactionAuditTrialReportRDLId15': 'Receivers',
  'TransactionAuditTrialReportRDLId16': 'ServiceLoaner',
  'TransactionAuditTrialReportRDLId17': 'Switchouts',
  'TransactionAuditTrialReportRDLId18': 'ChargeOffEPOPIF'
};

// Function to filter and map param values and paramKey
export const filterParamKeyValues = async (apiResponse = [], keyword = '', returnParamKeyName: any) => {
  return apiResponse
    .filter((item: any) => item.paramKeyName.includes(keyword)) // Filter by keyword
    .map((item: any) => {
      if (returnParamKeyName) {
        return {
          RDLReportId: item.paramValue,
          RDLReportName: item.paramKeyName
        };
      } else {
        return item.paramValue; // Return only paramValue as an array of strings
      }
    });
};

export const fetchingPBIReportId = async (objectsArray?: any, reportName?: any) => {
  console.log("reportNamereportName", reportName)
  if (Array.isArray(objectsArray)) {
    // Iterate through each object in the array
    for (const obj of objectsArray) {
      if (obj && typeof obj === 'object' && typeof obj.paramKeyName === 'string') {
        // Check if 'paramKeyName' contains the reportName
        if (obj.paramKeyName.includes(reportName)) {
          // Return the 'paramValue' when the substring is found
          return obj.paramValue;
        }
      }
    }
  } else {
    return null
  }
}

// Function for normalizeString

function normalizeString(str?: any) {
  return str.replace(/\s/g, '').toLowerCase();
}

// Function for form Array Of Objects

function formArrayOfObjects(reportNames?: any, RDLIds?: any, mapping?: any) {
  const nameMap = reportNames.reduce((acc: any, name: any) => {
    acc[normalizeString(name)] = name.trim();
    return acc;
  }, {});

  return RDLIds.map((item: any) => {
    const mappedName = mapping[item.RDLReportName] || 'Unknown';
    return {
      subreportName: nameMap[normalizeString(mappedName)] || 'Unknown',
      RDLReportName: item.RDLReportName,
      RDLId: item.RDLReportId
    };
  });
}

// Function for getParamValueBySubreportName
export function getParamDetailsBySubreportName(reportNames?: any, RDLIds?: any, mapping?: any, subreportName?: any) {
  const normalizedSubreportName = normalizeString(subreportName);
  const result = formArrayOfObjects(reportNames, RDLIds, mapping).find(
    (item: any) => normalizeString(item.subreportName) === normalizedSubreportName
  );
  return result ? result : ''
}
