/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, {
    useEffect,
    useState,
    useContext
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useHistory, useParams } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import './test.css';
import { getEmbedInfo, storeConfigCall, exportReport } from '../../api/user';
import { ContainerContext } from '../../app/App';
import { useStyles } from '../../app/App'
import { CircularProgress, Grid, RACButton, RACModalCard, Typography } from '@rentacenter/racstrap';
import { ReactComponent as Alert } from "../../assets/images/no-records-found.svg";

import { CustomPropInterface } from '../..';
import Modal from 'react-modal';
import clsx from "clsx";
import { ReportParameterMappings, DUAL_WORKSPACE_REPORTS, filterParamKeyValues, fetchingPBIReportId, getParamDetailsBySubreportName, TATRDLSubReportName, TATRDLSubReportMapping } from '../../constants/constants';

export const PBIReports = (props: any) => {
    // eslint-disable-next-line no-debugger
    const [loader, setloader] = useState(false);
    const [embedInfoResponse, setembedInfoResponse] = useState<any>('')
    const [rdlReportId, setrdlReportId] = useState<any>('')
    const [printBtnLoander, setPrintBtnLoader] = useState<boolean>(false)
    const [exportPdfBtnLoader, setExportPdfBtnLoader] = useState<boolean>(false)
    const [exportExcelBtnLoader, setExportExcelBtnLoader] = useState<boolean>(false)
    const [PBIReportEnabled, setPBIReportEnabled] = useState<boolean>(false)
    const [buttonDisable, setButtonDisable] = useState<any>({
        printButton: true,
        exportPdfButton: true,
        exportExcelButton: true

    })
    const [reportParameterMapping, setReportParameterMapping] = useState<any>()
    const [somethingWentwrongOpen, setSomethingWentwrongOpen] = useState<boolean>(false)
    const [TATRDLReportIdwithKey, setTATRDLReportIdwithKey] = useState<any>([])
    const containerData: any = useContext(ContainerContext) as CustomPropInterface;
    const classes = useStyles();
    const userId: any = window.sessionStorage.getItem('userId')
    let storeProfileRes: any

    const history: any = useHistory();
    const { reportName }: any = useParams();


    const reportParameterMappingFormFn = async (reportName?: string) => {
        console.log("reportName", reportName)
        switch (reportName) {
            case 'DailyReport':
                setReportParameterMapping(ReportParameterMappings.dailyReportParameterMapping)
                break
            case 'DailyActivityPlanner':
                setReportParameterMapping(ReportParameterMappings.dapReportParameterMapping)
                break
            case 'InventoryRecapReport':
                setReportParameterMapping(ReportParameterMappings.inventoryRecapReportParameterMapping)
                break
            case 'ItemBeingServicedReport':
                setReportParameterMapping(ReportParameterMappings.itemBeingServiceReportParameterMapping)
                break
            case 'SalesPersonRecapReport':
                setReportParameterMapping(ReportParameterMappings.salesPersonReportParameterMapping)
                break
            case 'SkipStolenReport':
                setReportParameterMapping(ReportParameterMappings.skipStolenReportParameterMapping)
                break
            case 'ExceptionAuditTrial':
                setReportParameterMapping(ReportParameterMappings.exceptionAuditTrailReportParameterMapping)
                break
            case 'PettyCashAnalysisDetailReport':
                setReportParameterMapping(ReportParameterMappings.pettyCashAnalysisDetailReportParameterMapping)
                break
            case 'PettyCashAnalysisSummaryReport':
                setReportParameterMapping(ReportParameterMappings.pettyCashAnalysisSummaryReportParameterMapping)
                break
            case 'ReceiptAuditTrailReport':
                setReportParameterMapping(ReportParameterMappings.receiptAuditTrailReportParameterMapping)
                break
            case 'InventoryOutAuditTrialReport':
                setReportParameterMapping(ReportParameterMappings.inventoryOutAuditTrialReportParameterMapping)
                break
            case 'InventoryInAuditTrialReport':
                setReportParameterMapping(ReportParameterMappings.inventoryInAuditTrialReportParameterMapping)
                break
            case 'IdleInventoryReport':
                setReportParameterMapping(ReportParameterMappings.idleInventoryReportParameterMapping)
                break
            case 'OnRentInventoryReport':
                setReportParameterMapping(ReportParameterMappings.onRentReportParameterMapping)
                break
            case 'TransactionAuditTrialReport':
                setReportParameterMapping(ReportParameterMappings.tatReportParameterMapping)
                break
            default:
                setReportParameterMapping(ReportParameterMappings.dailyReportParameterMapping)
        }
    }
    useEffect(() => {
        try {
            console.log('containerData', containerData);
            console.log('GetEmailId', containerData.GetEmailId());
            console.log('GetEmployeeId', containerData.GetEmployeeId());
            console.log("userIdsession", userId)
            reportParameterMappingFormFn(reportName)
            let paramKeyNames: any

            if (reportName == 'TransactionAuditTrialReport') {
                paramKeyNames = ['PBITransactionAuditTrialReportId', 'TransactionAuditTrialReportRDLId1', 'TransactionAuditTrialReportRDLId2', 'TransactionAuditTrialReportRDLId3', 'TransactionAuditTrialReportRDLId4', 'TransactionAuditTrialReportRDLId5', 'TransactionAuditTrialReportRDLId6', 'TransactionAuditTrialReportRDLId7', 'TransactionAuditTrialReportRDLId8', 'TransactionAuditTrialReportRDLId9', 'TransactionAuditTrialReportRDLId10', 'TransactionAuditTrialReportRDLId11', 'TransactionAuditTrialReportRDLId12', 'TransactionAuditTrialReportRDLId13', 'TransactionAuditTrialReportRDLId14', 'TransactionAuditTrialReportRDLId15', 'TransactionAuditTrialReportRDLId16', 'TransactionAuditTrialReportRDLId17', 'TransactionAuditTrialReportRDLId18']
            }
            else {
                paramKeyNames = ['PBIDailyReportId', 'PBIDailyActivityPlannerReportId', 'PBIInventoryRecapReportId', 'PBIItemBeingServicedReportId', 'PBISalesPersonRecapReportId', 'PBISkipStolenReportId', 'PBIExceptionAuditTrialReportId', 'PBIPettyCashAnalysisDetailReportId', 'PBIPettyCashAnalysisSummaryReportId', 'PBIReceiptAuditTrailReportId', 'PBIInventoryInAuditTrialReportId', 'PBIInventoryOutAuditTrialReportId', 'PBIIdleInventoryReportId', 'PBIOnRentInventoryReportId']
            }
            let PBIReportId: any
            let TATRdlIds: any

            // function for fetching the PBIReportId's
            const pageLoad: any = async () => {
                const payload: any = {
                    storeNumbers: [sessionStorage.getItem('storeNumber')],
                    paramKeyNames: paramKeyNames,
                };
                if (payload.storeNumbers[0] && payload.storeNumbers[0] != '' && payload.storeNumbers[0] != 'null' && payload.storeNumbers[0] != 'undefined') {
                    storeProfileRes = await storeConfigCall(payload);
                }

                PBIReportId = await fetchingPBIReportId(
                    storeProfileRes?.storeProfileResponse?.configDetails?.[0]?.configDetails,
                    `PBI${reportName}`
                );
                if (reportName == 'TransactionAuditTrialReport') {
                    TATRdlIds = await filterParamKeyValues(storeProfileRes?.storeProfileResponse?.configDetails[0]?.configDetails, 'TransactionAuditTrialReportRDLId', false)
                    setTATRDLReportIdwithKey(await filterParamKeyValues(storeProfileRes?.storeProfileResponse?.configDetails[0]?.configDetails, 'TransactionAuditTrialReportRDLId', true))
                    console.log('TATRdlIds', TATRdlIds)
                }
                console.log('PBIReportId', PBIReportId)
                apiCall()
            };
            pageLoad()
            const reportInfo = props && props.reportDetails && props.reportDetails.storeProfileResponse?.configDetails[0]?.configDetails.find((el: any) => el.paramKeyName == reportName)

            console.log('reportInfo', reportInfo);
            // Function for getting the Embedded PBI Report token
            const apiCall = async () => {

                if (DUAL_WORKSPACE_REPORTS.includes(reportName) && PBIReportId != '0' && TATRdlIds) {
                    setrdlReportId('')
                }
                else {
                    setrdlReportId(reportInfo?.paramValue)
                }
                if (reportInfo.paramValue != '0') {
                    setPBIReportEnabled(PBIReportId != '0' ? true : false)
                    setloader(true);
                    const payload = {
                        reportId: DUAL_WORKSPACE_REPORTS.includes(reportName) && PBIReportId != '0' && TATRdlIds ? TATRdlIds : [reportInfo?.paramValue],
                        userId: userId ? userId : containerData.GetEmployeeId(),
                        PBIReportId: PBIReportId != '0' ? PBIReportId : undefined,
                        isDualWorkspace: DUAL_WORKSPACE_REPORTS.includes(reportName) && PBIReportId != '0' ? true : false,
                        reportName: reportName
                    }
                    console.log("getEmbedInfoPayLoad", payload)
                    const embedInfo = await getEmbedInfo(payload);
                    console.log("embedInfoRes", embedInfo)
                    setButtonDisable({ ...buttonDisable, printButton: false, exportPdfButton: false, exportExcelButton: false })
                    setloader(false);
                    setembedInfoResponse(embedInfo);
                } else {
                    history.push({ pathname: reportInfo.url })
                }
            }

        } catch (e: any) {
            setloader(false);
        }
    }, [props.reportDetails, reportName])

    const basicFilter: models.IBasicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "UserPrivilege",
            column: "LDAP_USER_ID"
        },
        operator: "In",
        values: [userId ? userId : containerData.GetEmployeeId()],
        filterType: 1
    }

    // Function to Print/ Export Report
    const printReport = async (type?: string, format?: string) => {
        console.log("reportParameterMappingName", reportParameterMapping)
        type == 'Print' ? (setPrintBtnLoader(true)) : type == 'ExportPDF' ? (setExportPdfBtnLoader(true)) : (setExportExcelBtnLoader(true))
        setButtonDisable({ ...buttonDisable, printButton: true, exportPdfButton: true, exportExcelButton: true })
        const activePage = await (window as any).report.getActivePage();
        console.log("activePage", activePage);
        const visuals = await activePage.getVisuals();
        console.log("visual", visuals);

        const reportParameters: any[] = [];
        let TATSubreportRDLId: any = ''

        for (const v of visuals) {
            if (v.type == "slicer") {
                console.log("v", v)

                const chosenValue = await v.getSlicerState();
                const slicerName = chosenValue.targets[0].column;
                const slicerFilter = chosenValue.filters[0];
                const reportParamName = reportParameterMapping[slicerName];
                console.log("chosenValue", reportParamName, chosenValue);
                console.log("slicerFilter", slicerFilter)

                const totalValue = await v.exportData();
                console.log("totalValue", totalValue)

                const totalValueArray = totalValue.data.split('\r\n').slice(1, -1);

                console.log("totalValueArray", totalValueArray);

                if (slicerFilter && slicerFilter.operator == "NotIn") {
                    const paramValue = totalValueArray.filter((x: any) => !slicerFilter.values.includes(x.replace(/^"|"$/g, '')))
                    for (const value of paramValue) {
                        reportParameters.push({
                            name: reportParamName,
                            value: value
                        })
                    }
                }
                else if (slicerFilter && slicerFilter.operator == "In") {
                    for (const value of slicerFilter.values) {
                        reportParameters.push({
                            name: reportParamName,
                            value: value
                        })
                    }
                }
                else if (slicerFilter && slicerFilter.logicalOperator == 'And') {


                    if (PBIReportEnabled && reportName == 'TransactionAuditTrialReport') {
                        const getParamDetails: any = getParamDetailsBySubreportName(TATRDLSubReportName, TATRDLReportIdwithKey, TATRDLSubReportMapping, activePage?.displayName)

                        console.log("getParamDetails", getParamDetails)
                        if (getParamDetails) {
                            TATSubreportRDLId = getParamDetails?.RDLId,
                                reportParameters.push(
                                    {
                                        name: 'SubReports',
                                        value: getParamDetails?.subreportName
                                    }
                                )
                        }

                    }

                    for (const operator of slicerFilter.conditions) {
                        debugger
                        if (operator.operator == 'GreaterThanOrEqual' || operator.operator == 'GreaterThan') {
                            const slicerDate: any = new Date(operator.value);

                            operator.operator == 'GreaterThan' ? slicerDate.setDate(slicerDate.getDate() + 1) : slicerDate
                            const localDate = slicerDate.toLocaleDateString().split('/');
                            const finalDate = `${localDate[2]}-${localDate[0]}-${localDate[1]}`
                            reportParameters.push({
                                name: 'StartDate',
                                value: finalDate
                            })

                            if (((slicerName == 'DAY_DATE' || slicerName == 'Date1' || slicerName == 'Date') || (slicerName == 'TodayDefault' && reportParamName == 'EndDate')) && slicerFilter?.conditions?.length == 1 && (reportParamName == 'CalendarDate' || (operator.operator != 'GreaterThan' || operator.operator != 'GreaterThanOrEqual' || slicerName == 'Date1' || slicerName == 'Date' || (slicerName == 'TodayDefault' && reportParamName == 'EndDate')))) {
                                reportParameters.push({
                                    name: 'EndDate',
                                    value: new Date().toISOString().split('T')[0]
                                })
                            }
                        }
                        if (operator.operator == 'LessThan' || operator.operator == 'LessThanOrEqual') {
                            debugger
                            const slicerDate = new Date(operator.value);
                            operator.operator == 'LessThan' ? slicerDate.setDate(slicerDate.getDate() - 1) : slicerDate
                            const localDate = slicerDate.toLocaleDateString().split('/');
                            const finalDate = `${localDate[2]}-${localDate[0]}-${localDate[1]}`

                            reportParameters.push({
                                name: reportParamName == 'CalendarDate' ? 'CalendarDate' : reportParamName == 'StartDate' && slicerName != 'Date1' ? 'StartDate' : 'EndDate',
                                value: finalDate
                            })
                        }

                    }

                }
                else if (
                    (!slicerFilter && reportParamName == 'CalendarDate') ||
                    (slicerName == 'Date' && reportParamName == 'EndDate') ||
                    (slicerName == 'TodayDefault' && (reportParamName == 'EndDate' || reportParamName == 'StartDate'))
                ) {
                    const isCommonConditionMet =
                        (slicerName == 'DAY_DATE' && reportParamName == 'CalendarDate') ||
                        (slicerName == 'Date' && reportParamName == 'EndDate') ||
                        (slicerName == 'TodayDefault' && (reportParamName == 'EndDate' || reportParamName == 'StartDate'));

                    if (isCommonConditionMet) {
                        reportParameters.push({
                            name: 'StartDate',
                            value: (slicerName == 'TodayDefault' && reportParamName == 'StartDate')
                                ? totalValueArray[totalValueArray?.length - 1]?.split(' ')[0]
                                : totalValueArray[0]?.split(' ')[0]
                        });

                        if (!(slicerName == 'TodayDefault' && reportParamName == 'StartDate')) {
                            const slicerDate = new Date();
                            reportParameters.push({
                                name: 'EndDate',
                                value: slicerDate.toISOString().split('T')[0]
                            });
                        }

                    } else {
                        const slicerDate = new Date();
                        slicerDate.setDate(slicerDate.getDate());
                        reportParameters.push({
                            name: reportParamName,
                            value: slicerDate.toISOString().split('T')[0]
                        });
                    }
                }
                else if (!slicerFilter) {
                    for (const value of totalValueArray) {
                        reportParameters.push({
                            name: reportParamName,
                            value: value.replace(/^"|"$/g, '')
                        })
                    }
                }
            }
        }

        console.log("reportParameters", reportParameters);

        const payload = {
            reportType: 'RDL',
            reportId: TATSubreportRDLId ? TATSubreportRDLId : rdlReportId,
            exportFormat: format ? format : 'PDF',
            reportParameters: reportParameters,
            action: 'INITIATE EXPORT'
        }
        const exportReportRes = await exportReport(payload);
        console.log('exportReportRes', exportReportRes);

        const exportStatusPayload = {
            reportType: 'RDL',
            reportId: TATSubreportRDLId ? TATSubreportRDLId : rdlReportId,
            exportId: exportReportRes.id,
            action: 'EXPORT STATUS'
        }

        // Set Interval - start
        let intervalId: any;
        let elapsedTime = 0;
        let percentComplete = 0;
        const intervalDuration = 5000;
        const totalDuration = 80000;

        const pollFunction = async () => {
            const exportStatusRes = await exportReport(exportStatusPayload);
            console.log('exportStatusRes', exportStatusRes);
            percentComplete = exportStatusRes.percentComplete;
            if (percentComplete == 100) {
                clearInterval(intervalId);
                const exportFilePayload = {
                    reportType: 'RDL',
                    reportId: TATSubreportRDLId ? TATSubreportRDLId : rdlReportId,
                    exportId: exportReportRes.id,
                    action: 'DOWNLOAD REPORT'
                }

                const exportFile: any = await exportReport(exportFilePayload);
                console.log('exportFile', exportFile);

                type == 'Print' ? printPdfFromBase64(exportFile?.exportedFile) : exportBase64toPdf(exportFile?.exportedFile, format)
                type == 'Print' ? (setPrintBtnLoader(false), setButtonDisable({ ...buttonDisable, exportPdfButton: false, exportExcelButton: false })) : type == 'ExportPDF' ? (setExportPdfBtnLoader(false), setButtonDisable({ ...buttonDisable, printButton: false, exportExcelButton: false })) : (setExportExcelBtnLoader(false), setButtonDisable({ ...buttonDisable, printButton: false, exportPdfButton: false }))
            }

            elapsedTime += intervalDuration;

            if (elapsedTime >= totalDuration) {
                clearInterval(intervalId);
                // setSomethingWentwrongOpen(true)
                type == 'Print' ? (setPrintBtnLoader(false), setButtonDisable({ ...buttonDisable, exportPdfButton: false, exportExcelButton: false })) : type == 'ExportPDF' ? (setExportPdfBtnLoader(false), setButtonDisable({ ...buttonDisable, printButton: false, exportExcelButton: false })) : (setExportExcelBtnLoader(false), setButtonDisable({ ...buttonDisable, printButton: false, exportPdfButton: false }))
            }
        }

        intervalId = setInterval(pollFunction, intervalDuration);

        // Set Interval - end
    }


    // Function to convert Base64 to Blob
    const base64ToBlob = (base64: any, mime: any) => {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mime });
    };


    // Function to open the PDF in an iframe and trigger print
    const printPdfFromBase64 = (base64String?: any) => {
        const mimeType = 'application/pdf';
        const blob = base64ToBlob(base64String, mimeType);
        const url = URL.createObjectURL(blob);

        // Create an iframe and append it to the document
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);

        // Wait for the iframe to load and then trigger print
        iframe.onload = () => {
            const iframeWindow = iframe.contentWindow;
            if (iframeWindow) {
                iframeWindow.print()
            } else {
                console.error('Failed to access iframe contentWindow');
            }
        };
    };


    // Function for Exporting the base64 format into to pdf in new tab start
    const exportBase64toPdf = (base64String?: any, format?: string) => {
        let mimeType: any

        switch (format) {
            case 'XLSX':
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                break;
            default:
                mimeType = 'application/pdf'


        }
        const blob = base64ToBlob(base64String, mimeType);
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new tab
        window.open(url, '_blank');


    };


    // Something went wrong popup open

    const somethingWentWrongPopupFn = () => {
        return (
            <div>
                <Grid item md={12} className={classes.textCenter}>
                    <Alert></Alert>
                    <Typography className={`${classes.popupText} ${classes.mt3}`}>
                        Unable to Export Report
                    </Typography>
                </Grid>
                <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => setSomethingWentwrongOpen(false)}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    };

    return (
        <div
            style={{
                marginTop: '50px',
            }}
        >
            <Modal
                isOpen={loader}
                className={classes.loaderStyle}
                id="openpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid>
                    <Grid>
                        <Grid className={classes.RACLoaderPage}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            {' '}
            {embedInfoResponse?.accessToken ? (
                <div>
                    {' '}
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',
                            id: `${embedInfoResponse?.embedUrl[0]?.reportId}`,
                            embedUrl: `${embedInfoResponse?.embedUrl[0]?.embedUrl}&rp:UserID=${userId ? userId : containerData?.GetEmployeeId()}`,
                            accessToken: `${embedInfoResponse?.accessToken}`,
                            tokenType: models.TokenType.Embed,
                            filters: [basicFilter],
                            settings: {

                                visualSettings: {
                                    visualHeaders: [
                                        {
                                            settings: {
                                                visible: false
                                            }
                                        }
                                    ]
                                },
                                panes: {
                                    pageNavigation: {
                                        visible: false
                                    }
                                }
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        console.log('Report loaded');
                                    },
                                ],
                                [
                                    'rendered',
                                    function () {
                                        console.log('Report rendered');
                                    },
                                ],
                                [
                                    'error',
                                    function (event: any) {
                                        console.log(event?.detail);
                                    },
                                ],
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embeddedReport: any) => {
                            console.log('embedded report', embeddedReport);
                            (window as any).report = embeddedReport;
                        }}
                    />{' '}
                </div>
            ) : null}
            <Grid
                item
                classes={{
                    root: clsx(classes.customerToolbar, classes.fixedBottom),
                }}
                style={{ zIndex: 0 }}
            >
                <Grid item >
                    <RACButton
                        className={classes.spacerMR2}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            history.push({ pathname: `/reporting` });
                        }}
                    >
                        Cancel
                    </RACButton>

                    {PBIReportEnabled ?
                        <>
                            <RACButton
                                className={`${classes.spacerMR2}  ${classes.floatRight}`}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                    printReport('Export', 'XLSX')
                                }}
                                loading={exportExcelBtnLoader}
                                disabled={buttonDisable.exportExcelButton}

                            >
                                Export to Excel
                            </RACButton>

                            <RACButton
                                className={`${classes.spacerMR2}  ${classes.floatRight}`}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                    printReport('ExportPDF')
                                }}
                                loading={exportPdfBtnLoader}
                                disabled={buttonDisable.exportPdfButton}

                            >
                                Export to PDF
                            </RACButton>
                            <RACButton
                                className={`${classes.spacerMR2}  ${classes.floatRight} `}
                                style={{ float: 'right' }}
                                size="small"
                                variant="contained"
                                color="primary"

                                onClick={async () => {
                                    printReport('Print')

                                }}
                                loading={printBtnLoander}
                                disabled={buttonDisable.printButton}
                            >
                                Print
                            </RACButton>
                        </>
                        : null}

                </Grid>
            </Grid>

            <RACModalCard
                isOpen={somethingWentwrongOpen}
                closeIcon={true}
                borderRadius="20px"
                maxWidth="sm"
                onClose={() => setSomethingWentwrongOpen(false)}
                // eslint-disable-next-line react/no-children-prop
                children={somethingWentWrongPopupFn()}
            />

        </div>
    );
};
